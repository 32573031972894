import {
  MY_CMS_URL_COMPOSE,
  MY_CMS_URL_DEV,
  MY_CMS_URL_PROD,
  MY_CMS_URL_QAT,
  MY_CMS_URL_UAT,
} from '@constants/index';

export default () => {
  switch (process.env.GATSBY_ENV) {
    case 'prod':
      return MY_CMS_URL_PROD;
    case 'uat':
      return MY_CMS_URL_UAT;
    case 'qat':
      return MY_CMS_URL_QAT;
    case 'dev':
      return MY_CMS_URL_DEV;
    case 'compose':
      return MY_CMS_URL_COMPOSE;
    case 'local':
      return MY_CMS_URL_UAT;
    default:
      return '*';
  }
};
